.table-status {
    padding: 5px 8px;
    font-size: 0.9rem;
    border-radius: 5.46px;
}
.form-title {
    margin-bottom: 6.5px;
}
.star-icon {
    position: relative;
    width: 13px;
    bottom: 2px;
}
.error-text {
    color: #f64e60;
}
.w--600 {
    width: 600px;
}
.publication-icon {
    margin: unset !important;
    &.Mui-expanded {
        margin-bottom: unset !important;
    }
    .MuiAccordionSummary-root {
        align-items: unset !important;
        .MuiAccordionSummary-expandIcon.Mui-expanded {
            transform: unset !important;
        }
        .Mui-expanded {
            margin: 12px 0 !important;
            padding: unset !important;
        }
        .MuiIconButton-root {
            max-width: 40%;
        }
    }
}

.setting-menu {
    @extend .publication-icon;

    .MuiAccordionSummary-root {
        background-color: #f5f5f5 !important;
        min-height: 48px !important;
    }
    .MuiAccordionDetails-root {
        padding-top: 0 !important;
    }
}
.img-cover {
    width: 400px;
    height: 240px;
    object-fit: contain;
}
.img-logo {
    width: 60px;
    height: 60px;
    object-fit: contain;
}
.search-engine {
    box-shadow: 0 5px 35px var(--color-black-6);
    background-color: #eceff6;
    padding: 20px 16px;
    border-radius: 4px;
}
.setting-menu-tab {
    .MuiAppBar-colorPrimary {
        background: unset !important;
        box-shadow: unset;
    }
    .MuiTabPanel-root {
        padding: 3px 0 0 0;
        margin-top: 4px;
    }
    .MuiTab-root {
        text-transform: unset !important;
    }
}
.MuiFormControlLabel-root {
    margin: 0 !important;
    padding: 0 !important;

    .MuiButtonBase-root {
        padding: 0 !important;
    }
}
.select-menu-item {
    border-radius: 4px;
    border: 1px solid #dddddd;
    background-color: #fdfdfd;
    padding: 10px;
}
.list-menu {
    padding: 10px;
    background-color: #f5f5f5;
    @extend .row-sb;
}
.list-menu-top-border-bottom {
    @extend .list-menu;
    border-bottom: 1px solid #dddddd;
}
.list-menu-top-border-top {
    @extend .list-menu;
    border-top: 1px solid #dddddd;
}
.list-menu-middle {
    padding: 20px 10px;
    background-color: #ffffff;

    @include mb-down {
        margin-left: 4px;
    }

    .text {
        border-bottom: 1px solid #f5f5f5;
        padding-bottom: 30px;
        width: 100%;
        padding: 10px;
    }
}
.setting-menu-ctn {
    .left {
        padding-right: 0;
        .left-item {
            padding-right: 25px;
            margin-top: 20px;

            @include mb-down {
                padding-right: unset;
            }
        }
    }
    .right {
        margin-top: 20px;
        .title {
            @include mb-down {
                .row {
                    margin: 0 !important;
                    padding: 0 !important;
                }
            }
        }
    }
}

.create-menu-ctn {
    @include mb-down {
        margin-top: 10px;
    }
}
.row {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.header-setting-menu-ctn {
    background-color: #ffffff;

    .header-setting-menu {
        @extend .rowy-center;

        @include mb-down {
            display: flex;
            flex-direction: column;
            justify-content: unset;
            align-items: unset;
        }

        .left {
            @extend .rowy-center;
        }
        .right {
            @include mb-down {
                margin-top: 10px;
            }
        }
    }
}
.rst__rowLabel {
    width: 100%;
    padding-right: unset !important;
}
.rst__rowContents {
    padding-right: 10px !important;
}



.widget-body {
    .sc-bdfBwQ {
        &.ffLdwt {
            border: 1px solid #ccd0d4 !important;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04) !important;
            padding: 0 !important;
        }
    }
    .sc-hKgILt {
        &.fGneGQ {
            border: 1px solid #ccd0d4 !important;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04) !important;
            padding: 0 !important;
            margin-bottom: 0 !important;
        }
    }
}

.widget-ctn {
    border: 1px solid #ccd0d4 !important;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04) !important;
    width: 448px;

    @include mb-down {
        width: 100%;
    }
}
.left-widget-data {
    padding-right: 12.5px !important;

    @include mb-down {
        padding-right: 0;
    }
}
.customUploadButton {
    display: none;
}

.mediaIconSearchActive {
    color: #3699ff !important;
}

.item-media-ctn {
    padding: 20px 16px 20px 16px;

    @include mb-down {
        padding-top: 0;
        &:first-child {
            padding-top: 20px;
        }
    }

    .item-media {
        border-radius: 4px;
        padding: 26px 29.25px;
        cursor: pointer;
        background-color: #fff;

        &:hover {
            background-color: #fafafa;
        }

        .img-media {
            padding-top: 70px;
        }
    }
}
.customModal {
    .modal-dialog {
        width: 45%;
        max-width: none !important;

        .modal-content {
            margin-top: 5rem;

            @include mb-down {
                width: 97vw;
            }
            .modal-header {
                height: 50px;
                border: none;
                padding: 16px;
                background-color: #33b5e5 !important;
                box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;

                .modal-title {
                    width: 100%;
                }
            }
            .modal-body {
                padding: 16px;
            }
            .modal-footer {
                padding: 16px;
                border: none;
                display: flex;
                justify-content: flex-start;
            }
        }
    }
}

.title-input-media {
    width: 5rem;
}
.right-cotent-modal-media {
    padding-left: 60px !important;

    @include mb-down {
        padding-left: 0 !important;
        padding-bottom: 20px;
    }
}
.left-cotent-modal-media {
    height: 320px;
    padding-bottom: 20px;
}
.col-lg-6 {
    padding-left: 0;
    padding-right: 0;
}
.col-lg-12 {
    padding-left: 0;
    padding-right: 0;
}
.item-search {
    @include mb-down {
        margin-top: 10px;
        padding: 0;
    }
}
.search-icon {
    margin-top: 0.6rem;
    padding: 0 12.5px 5px 0;

    @include mb-down {
        padding-bottom: 0;
    }
}

.customDropzoneArea {
    .MuiDropzoneArea-textContainer {
        height: 100%;
        @extend .column-center;
    }
}
.MuiPaper-root {
    &.MuiSnackbarContent-root,
    &.MuiDropzoneSnackbar-successAlert,
    &.MuiPaper-elevation6 {
        position: absolute;
        bottom: 0;
    }
}

.form-control:disabled,
.form-control[readonly] {
    border: unset;
    cursor: not-allowed;
    pointer-events: all !important;
}

.action-column {
    padding-right: 0.75rem !important;
}

.row-search {
    @include mb-down {
        width: 100%;
    }
}

.col-search {
    padding-left: 0 !important;
    padding-right: 10px !important;

    @include mb-down {
        padding-right: 0 !important;
        width: 100%;
    }
}

.col-edit {
    width: 80%;
}

.starDanger:after {
    content: "*";
    color: red;
    padding-left: 5px;
}

.ant-upload-list {
    &.ant-upload-list-text {
        display: none !important;
    }
}
.ant-upload-list {
    &.ant-upload-list-text-custom {
        display: block !important;
    }
}

.pc-title {
    font-weight: 500;
    font-size: 1.275rem;
    color: #181c32;
}

.aside-menu .menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-link {
    padding: 0 0 0 40px !important;
}

.dump-trash-search {
    min-width: 180px !important;
}

.add-user-col {
    margin-top: 20px;
}

.add-user-avatar {
    img {
        width: 300px;
        height: 250px;
        object-fit: cover;
    }
}

.img-upload {
    width: 300px;
    height: 250px;
    object-fit: cover;
}

.customNameTable {
    max-width: 12rem;

    .nameTable {
        @extend .break-word-2-row;
    }
}

.form-group {
    margin-bottom: 0 !important;
}

.tmar-spin-container .ant-spin-spinning {
    z-index: 9999 !important;
    min-height: 81vh !important;

    &.ant-spin {
        max-height: 81vh !important;
    }
}

.checkbox-aggregator {
    padding-bottom: 3px;
    margin-left: 0 !important;
}

.footer {
    position: fixed;
    width: 100%;
    bottom: 0;
}

.flex-column-fluid {
    .container {
        padding-bottom: 40px;
        background-color: #eceef7 !important;
        min-height: calc(100vh - 16rem) !important;
    }
}
.modal-row {
    border-bottom: 1px solid #ebedf3;

    .modal-item-title {
        padding-left: 0;
        padding-right: 0;
        font-size: 13px;
        line-height: 20px;
        min-height: 35px;
        display: flex;
        align-items: center;
        font-weight: 600;

        @include mb-down {
            min-height: 25px;
        }
    }

    .modal-item-text {
        padding-left: 10px;
        padding-right: 0;
        font-size: 13px;
        line-height: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;

        @include mb-down {
            padding-left: 0;
            min-height: 30px;
        }
    }
}

#kt_content {
    background-color: #eceef7 !important;
}

.projectName {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    color: #ffffff;
}

.table-no-mt {
    @include mb-down {
        margin-top: 0 !important;
    }
}

.table th,
.table td {
    vertical-align: middle;
}

@include mb-down {
    .mb-btn-search {
        margin-top: 13px;
    }
    .add-user-col {
        margin-top: 10px;
    }
    .email-col-add-user {
        margin-top: 10px;
    }
    .col-edit {
        width: 100%;
    }
    .add-user-avatar {
        img {
            width: 69%;
            min-width: 69%;
            max-width: 69%;
            height: 150px;
        }
    }
    .img-upload {
        width: 69%;
        min-width: 69%;
        max-width: 69%;
        height: 150px;
    }
    .mb-form {
        width: 100%;
    }
    .form-group {
        margin-bottom: unset;
    }
    .mb-mt-10 {
        margin-top: 10px;
    }
}

.ant-popover {
    &.ant-popover-placement-bottom {
        top: 45px !important;
    }
}

.ant-popover-inner-content {
    padding: 10 !important;
}

.customEditBtn {
    color: #f6635b;
    font-size: 14px;
    font-weight: 600;
}

.table-img {
    img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        object-fit: cover;
        margin-bottom: 10px;
        cursor: pointer;
    }
}

.whatsappmodal {
    &.ant-modal,
    &.ant-zoom-appear,
    &.ant-zoom-appear-active &.ant-zoom {
        width: 100% !important;
        height: 100% !important;
        .ant-modal-content {
            background: none !important;
            .ant-modal-header {
                padding: 0 !important;
                background: none !important;
                border: none !important;
            }
            .ant-modal-body {
                padding: 0 !important;
                background-image: url("../assets/images/greybackground.JPG");
            }
        }
    }
}

.img-carosel-container {
    height: calc(100vh - 4.5rem);
    display: flex !important;
    width: 100%;
    justify-content: center;
    align-items: center !important;
    background-image: url("../assets/images/greybackground.JPG");

    .img-carosel {
        background-color: white;
        border-radius: 16px;
        padding: 16px;
        height: 62%;

        img {
            height: 100%;
            object-fit: contain;
        }
    }
}

.modal-casosel-body-header {
    @extend .modal-body-header;
    background-image: url("../assets/images/greybackground.JPG");

    .modal-icon {
        cursor: pointer;
        z-index: 5;
        position: absolute;
        left: 95.2vw;
        width: 37px;
    }
}

.modal-body-header {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;

    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    background-color: var(--color-white);

    .modal-icon {
        position: absolute;
        left: 56rem;
        cursor: pointer;
    }
}

.left-arrow-carosel {
    top: 45%;
    width: 2.5rem;
    position: absolute;
    left: 1%;
    cursor: pointer;
    z-index: 1;
}
.right-arrow-carosel {
    top: 45%;
    width: 2.5rem;
    position: absolute;
    right: 1%;
    cursor: pointer;
    z-index: 1;
}

.view-more {
    text-decoration: underline;
    color: #3699ff;
    font-size: 12px;
    cursor: pointer;
}

.general-info {
    font-size: 20px;

    @media (max-width: 991px) {
        font-size: 17px;
    }
}

.group-role-ctn {
    padding: 16px 16px 0 16px;
    border: 1px solid #e9eef7;
    border-radius: 8px;
    width: 100%;
}

.customNoiQuy {
    @media (max-width: 991px) {
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        .col-sm-6 {
            width: 80% !important;
        }
    }
}

.card-thong-ke-ctn {
    margin-bottom: 24px;
    @media (max-width: 991px) {
        margin-bottom: 20px;
        height: unset;
        margin-bottom: 16px;

        .card-thong-ke {
            height: unset;
            min-height: 150px;
        }
    }
}

.card-custom {
    margin-bottom: 0 !important;
}

.comment-page {
    // background-image: url("http://localhost:3000/uploads/1626145713643-Rectangle 22.png");
    // background-size: cover;
    // padding-top: 99px;

    .comment-ctn {
        height: 100%;
        background: rgba(17, 25, 31, 0.48);

        .header {
            height: 40px;
        }

        .top-ctn {
            padding: 16px;
            // background: #004e7b;
            background: rgba(255, 255, 255, 0.07);
            border-radius: 4px;

            .top {
                .top-content {
                    max-width: 100%;
                    overflow-x: auto;
                    padding-bottom: 25px;
                    position: relative;
                    width: 100%;

                    .img-under {
                        position: relative;
                        top: 1.5rem;
                        right: 0.8rem;
                        border: 2px solid #ffffff;
                    }
                }

                .bottom-content {
                    padding: 15px;
                }
            }
        }
    }
}

.c-FFFFFF {
    color: #ffffff;
}

.customIconRightDashboard {
    padding: 10px;
    border: 1px solid #ebedf3;
}

.fs--3phay5rem {
    font-size: 3.5rem;
}

.list-demo-canvas {
    font-family: sans-serif;
    text-align: center;
    min-height: 100vh;
    font-family: FVH Circular XX;
    background: #ffffff;

    .title {
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        color: #141416;
        padding: 30px 39px 0 39px;
        font-family: FVH Circular XX;
    }

    .sub-title {
        padding-left: 12px;
        padding-right: 12px;

        .description-grey {
            font-family: FVH Circular XX;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #777e91;
            font-weight: 700;
        }

        .description-orange {
            font-family: FVH Circular XX;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #ff4a00;
            font-weight: 700;
        }
    }
}

.customBtn123 {
    background: none;
    border: 1px solid black;
    font-size: 20px;
    border-radius: 5px;
}

#wheel {
    border-radius: 50%;
    border: 10px solid #ffc102;
}

#spin,
#reset {
    position: absolute;
    bottom: 20vh;
    right: 45vw;
}

#spin:hover {
    color: rgb(0, 180, 120);
    border-color: rgb(0, 180, 120);
}

#spin:focus,
#reset:focus {
    outline: none;
}

/* #reset {
    position: absolute;
    bottom: 20vh;
    right: 35vw;
  } */

#reset:hover {
    color: red;
    border-color: red;
}

#readout {
    position: absolute;
    bottom: 7vh;
    left: 35vw;
}

#selector {
    position: absolute;
    top: 11.7rem;
    right: 44vw;
    font-size: 40px;
    z-index: 3;
    transform: rotate(-30deg);
}

#result {
    font-size: 50px;
    color: rgb(0, 180, 120);
}

.canvas-btn-ctn {
    padding: 25px 20px;
    width: 100%;

    .canvas-btn {
        background: #ff4a00;
        border-radius: 4px;
        height: 40px;
        width: 100%;
        border: none;

        span {
            font-family: FVH Circular XX;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 20px;
            color: #ffffff;
        }
    }
}

.customDotMiddleWheel {
    position: absolute;
    top: 20.8rem;
    left: 41.7vw;
}

.sizeImg {
    width: 37.71px;
    height: 37.71px;
}

.visibleHidden {
    visibility: hidden;
    max-height: 0;
}
// .product-update{
//     .ant-upload-list-picture-card-container:first-child{
//         width: 172px !important;
//         height: 172px !important;
//         display: block;
//         position: absolute;
//         left: -180px;
//     }
//     .ant-upload-list-picture-card-container, .ant-upload.ant-upload-select-picture-card{
//         width: 80px !important;
//         height: 80px !important;
//     }
// }

.codex-editor{
    border: 1px solid #0a0a0a !important;
}
.ce-block__content,
.ce-toolbar__content {
    max-width: unset;
}
.codex-editor .codex-editor__redactor{
    padding-bottom: 100px !important;
    padding-left: 20px !important;
}


.ant-pro-footer-bar {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 24px;
    line-height: 44px;
    background: #fff;
    border-top: 1px solid #f0f0f0;
    //box-shadow: 0 -6px 16px -8px rgb(0 0 0 / 8%), 0 -9px 28px 0 rgb(0 0 0 / 5%), 0 -12px 48px 16px rgb(0 0 0 / 3%);
    transition: width .3s cubic-bezier(.645,.045,.355,1);
    width: calc(100% - 266px)
}

.ant-pro-footer-bar-left {
    flex: 1 1;
}


.ant-layout, .ant-layout * {
    box-sizing: border-box;
}
.aside-minimize .ant-pro-footer-bar{
    width: calc(100% - 70px)
}

.aside-minimize .menu-product-tree, .aside-minimize-hover .menu-product-tree{
    position: fixed;
    top: 143px;
    left: 70px;
    z-index: 10;
}
.menu-product-tree{
    position: fixed;
    top: 143px;
    left: 265px;
    z-index: 10;
}

.menu-product-tree .ant-tree{
    height: 888px;
    overflow: hidden;
}
.product-right{
    margin-left: 278px;
    
}
.aside-minimize .product-right, .aside-minimize-hover .product-right{
    margin-left: 197px;
}

.menu-product-tree .card.card-custom.gutter-b{
    padding : 25px;
}

// #kt_content{
//     padding-top: 0px !important;
// }

.site-tree-search-value {
    color: #f50;
}
.product-update .ce-toolbar__plus{
    left: -20px;
}
.cst-right{
    display: contents !important;
}
#kt_content .ant-tabs-nav-list{
    margin-left: 16px;
}
.list-properties{
    margin: 10px !important;
}

.dropdown-antd-custom{
    background-color:aliceblue
}
.fl-left{
    float: left;
}
.fl-right{
    float: right;
}