.bg--EEE5FF {
    background-color: #eee5ff;
}
.c--8950FC {
    color: #8950fc;
}

.bg-FFFFFF {
    background: #ffffff;
}

.bg--C9F7F5 {
    background-color: #c9f7f5;
}
.c--1BC5BD {
    color: #1bc5bd;
}
.c--7E8299 {
    color: #7e8299;
}
.border-top-EBEDF3 {
    border-top: 1px solid #ebedf3;
}
.c--FFFFFF {
    color: #ffffff;
}
.bg--FFFFFF {
    background-color: #ffffff;
}
.c--686868 {
    color: #686868;
}
.c--F4B403 {
    color: #f4b403;
}
.border-016067 {
    border: 1px solid #016067;
}
.c-016087 {
    color: #016087;
}
.c--0071a1 {
    color: #0071a1;
}
.c--32373c {
    color: #32373c;
}
.bg-F5F5F5 {
    background-color: #f5f5f5;
}
.c-0073AA {
    color: #0073aa;
}
.bg-0073AA {
    background-color: #0073aa;
}
.c-AA0000 {
    color: #aa0000;
}
.c--23282D {
    color: #23282d;
}
.c--555D66 {
    color: #555d66;
}
.c-666666 {
    color: #666666;
}
.bg-EAEDF4 {
    background-color: #eaedf4;
}
.c-000000 {
    color: #000000;
}

.bg-E6F8FD {
    background: #e6f8fd;
}
.c--3F4254 {
    color: #3f4254;
}
.c-B5B5C3 {
    color: #b5b5c3;
}

.bg-F64E60 {
    background: #F64E60;
}

.c-F64E60 {
    color: #F64E60;
}

.bg-9EBC30 {
    background: #9EBC30;
}

.c-000000D9 {
    color: #000000D9;
}

.c-3699FF {
    color: #3699FF;
}

.c-1BC5BD {
    color: #1BC5BD;
}