@import "./message.scss";
@import "./atomics.scss";
@import "./lib.scss";
@import "./mixin.scss";
@import "./color.scss";
@import "./pagination.scss";
@import "./form.scss";
@import "./select.scss";
@import "./custom.scss";

body {
    font-family: "Poppins", sans-serif;
}
